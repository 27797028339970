export const PLAN_TYPE = { 
  PRO_PUBLISHER_PLAN: 'PRO_PUBLISHER_PLAN', 
  PRO_PUBLISHER_PLAN_PLUS: 'PRO_PUBLISHER_PLAN_PLUS', 
  PRO_PUBLISHER_PLAN_PLUS_AI: 'PRO_PUBLISHER_PLAN_PLUS_AI'
}

export const APP_OPTION = {
  NO_APP_REQUIRED: 'NO_APP_REQUIRED',
  WITHOUT_APP_PURCHASES_AND_PAID_SUBSCRIPTIONS : 'WITHOUT_APP_PURCHASES_AND_PAID_SUBSCRIPTIONS',
  WITH_APP_PURCHASES_AND_PAID_SUBSCRIPTIONS : 'WITH_APP_PURCHASES_AND_PAID_SUBSCRIPTIONS',
}

export const PRICE_TYPE = {
  VAT: 'VAT', TAXFREE: 'TAXFREE'
}