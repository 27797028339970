import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { Button, Header, Grid, Checkbox } from 'semantic-ui-react';
import native from '../../../assets/native.png'
import "./NativeApp.css"
import RenderField from '../common/RenderFieldRedux';
import { APP_OPTION } from './common';
import axios from 'axios';

class NativeApp extends React.Component {
  state = {
    appOption: APP_OPTION.NO_APP_REQUIRED,
    appList: []
  }

  onPaste = (e) => {
    e.preventDefault();
  }
  
  onAppChange(appOption){
    this.props.change('appOption', appOption);
    this.setState({ appOption })
  }

  async componentDidMount(){

    const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v2/product?category=APP_OPTION`)
    this.setState({ appList: response.data })


    this.props.change('appOption', this.state.appOption);
  }

  render() {
    const { handleSubmit, previousPage } = this.props;
    return (
      <form onSubmit={handleSubmit} size='large'>
        <Header>
          Please select desired option and click Next
        </Header>
        <Field
          component={RenderField}
          name="appOption"
          value={this.state.appOption}
          type="hidden"
        />
        <Grid stackable>
          <Grid.Row centered>
            <Grid.Column width={4} style={{ textAlign: 'center', marginTop: '1em'}}>
              <img alt="#" src={native} />  
            </Grid.Column>
            <Grid.Column width={8} className="native-content" >
              <div>
                <b>Would you like to add a white label mobile app to your order?</b>
              </div>
              <div style={{ marginTop: '0.5em'}}>
                We build and deploy your own branded apps with no set up feeIncludes maintenance 
                and support:     
              </div>
              <div style={{ marginTop: '0.5em'}}>
                Mobile app, natively coded for both iOS and Android, deployed and maintained on 
                Google Play and Apple App Store   
              </div>
              <div style={{ marginTop: '0.5em', marginBottom: '0.5em'}}>Would you like to add a white label mobile app to your order?</div>

              {this.state.appList.map( (item, idx) => 
                {
                  let label = item.price>0?`${item.name} - £${item.price}`:item.name
                  return <Checkbox key={idx} radio name='app'  style={{ marginTop: '0.5em', marginLeft: '0.5em'}}
                  label={label} 
                  value={2}
                  checked={this.state.appOption===item.key} 
                  onChange={(e, data) => this.onAppChange(item.key)}/>               
                }
              )}
            </Grid.Column>
          

           </Grid.Row> 
           <Grid.Row centered> 
              <Button color='blue' style={{ background: '#ea5348'}} onClick={previousPage}>
                Previous
              </Button>
              <Button color='blue'  style={{ background: '#ea5348'}} disabled={this.state.appOption==null}>
                Next
              </Button>
            </Grid.Row>
        </Grid>

      </form>
    )
  }
}

const validate = (formValues) => {
  const errors = {};
  return errors;
}


export default reduxForm({
  form: 'wizard', //                 <------ same form name
  destroyOnUnmount: false, //        <------ preserve form data
  forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
  validate
})(NativeApp);
