import React from 'react';
import { Grid, Header, Segment, Icon, Step, Image, Modal, Button } from 'semantic-ui-react';
import axios from 'axios';
import Logo from './../../assets/imgpsh_fullsize_anim.png';
import CompanyInfo from './steps/CompanyInfo';
import BillingInfo from './steps/BillingInfo';
import PaymentInfo from './steps/PaymentInfo';
import PlanType from './steps/PlanType';
import NativeApp from './steps/NativeApp';

class SuccessMessage extends React.Component {
  render()
  {
    return <div className='success-message'>
              <div className='container'>
                <div className='ui header' style={{ fontSize: '1.8em'}}>
                  Welcome to Flickread!
                </div>

                <div className='ui header' style={{ marginTop: '5em'}}>
                  Thank you for signing up to Flickread
                </div>

                <div className='ui header'>You have signed up to:</div>

                <div className='ui header'>{this.props.plan}</div>
                <div className='ui header'>and will be charged per month</div>
                <div className='ui header'>£{this.props.price}</div>

                <div className='ui header' style={{ marginTop: '2.5em'}}>
                  To start publishing content please sign in using the credentials you created
                </div>
                <div className='ui header' style={{ marginBottom: '10em'}}>
                  <Button color='blue' 
                    onClick={() => window.location = "https://publish.apazine.com"}
                    style={{ background: '#ea5348'}}>
                    Sign In
                  </Button>
                </div>
              </div>
          </div>
  }
}

export class Signup extends React.Component {
  state = {
    currentStep: 1,
    isLoading: false,
    modalProcessingFailed: false,
    showSuccessMessage: false,
    successMessagePlan: '',
    successMessagePrice: 0,    
    productList: []
  }

  nextStep = () => {
    this.setState({ currentStep: this.state.currentStep + 1 })
  }

  prevStep = () => {
    this.setState({ currentStep: this.state.currentStep - 1 })
  }

  onTriggerLoading = () => {
    this.setState({ isLoading: true })
  }

  getStripeRedirect = () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const payment_intent = urlParams.get('payment_intent')
    const payment_intent_client_secret = urlParams.get('payment_intent_client_secret')
    const redirect_status = urlParams.get('redirect_status')

    return { payment_intent, redirect_status }
  }

  async componentDidMount() {
    // Get product list
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/product`)
    this.setState({ productList: response.data })


    // Checking whether we are coming back from redirect
    const { payment_intent, redirect_status } = this.getStripeRedirect()
    if( payment_intent && redirect_status === 'succeeded'){
      // Get product name
      const data = JSON.parse( localStorage.getItem("data") )
      
      // Register user
      await axios.post(`${process.env.REACT_APP_API_URL}/api/user/register/`, { ...data })

      // Remove sensible data
      localStorage.removeItem("data")

      // successMessage parameters
      const product = this.state.productList.find( item => item.key == data.planType)
      this.setState({ 
          successMessagePlan: product.name,
          successMessagePrice: data.price
      })
    }

  }


  // onSubmit = async (formValues) => {
  //   formValues.stripeTokenCard = window.stripeToken.card.id
  //   delete window.stripeToken
    
  //   // Update price with appOption
  //   formValues.price = formValues.priceTotalPlusVAT

  //   // Get product name
  //   const product = this.state.productList.find( item => item.key == formValues.planType)
  //   const planTypeName = product.name
    

  //   // Compute plan Type
  //   // 1 = Pro Publisher Plan
  //   // 2 = Pro Publisher Plus
  //   // 3 = Pro Publisher Plus Speech
  //   // 4 = Pro Publisher Plan and APP with Free Content
  //   // 5 = Pro Publisher Plus and APP with Free Content
  //   // 6 = Pro Publisher Plus Speech and APP with Free Content
  //   // 7 = Pro Publisher Plan and APP with Paid Content
  //   // 8 = Pro Publisher Plus and APP with Paid Content
  //   // 9 = Pro Publisher Plus Speech and APP with Paid Content
  //   if(formValues.planType == PLAN_TYPE.PRO_PUBLISHER_PLAN &&
  //       formValues.appOption == APP_OPTION.NO_APP_REQUIRED )
  //     formValues.planType = 1
  //   else if(formValues.planType == PLAN_TYPE.PRO_PUBLISHER_PLAN_PLUS &&
  //       formValues.appOption == APP_OPTION.NO_APP_REQUIRED )
  //     formValues.planType = 2
  //   else if(formValues.planType == PLAN_TYPE.PRO_PUBLISHER_PLAN_PLUS_AI &&
  //       formValues.appOption == APP_OPTION.NO_APP_REQUIRED )
  //     formValues.planType = 3

  //   if(formValues.planType == PLAN_TYPE.PRO_PUBLISHER_PLAN &&
  //     formValues.appOption == APP_OPTION.WITHOUT_APP_PURCHASES_AND_PAID_SUBSCRIPTIONS )
  //     formValues.planType = 4
  //   else if(formValues.planType == PLAN_TYPE.PRO_PUBLISHER_PLAN_PLUS &&
  //       formValues.appOption == APP_OPTION.WITHOUT_APP_PURCHASES_AND_PAID_SUBSCRIPTIONS )
  //     formValues.planType = 5
  //   else if(formValues.planType == PLAN_TYPE.PRO_PUBLISHER_PLAN_PLUS_AI &&
  //       formValues.appOption == APP_OPTION.WITHOUT_APP_PURCHASES_AND_PAID_SUBSCRIPTIONS )
  //     formValues.planType = 6

  //   if(formValues.planType == PLAN_TYPE.PRO_PUBLISHER_PLAN &&
  //     formValues.appOption == APP_OPTION.WITH_APP_PURCHASES_AND_PAID_SUBSCRIPTIONS )
  //     formValues.planType = 7
  //   else if(formValues.planType == PLAN_TYPE.PRO_PUBLISHER_PLAN_PLUS &&
  //       formValues.appOption == APP_OPTION.WITH_APP_PURCHASES_AND_PAID_SUBSCRIPTIONS )
  //     formValues.planType = 8
  //   else if(formValues.planType == PLAN_TYPE.PRO_PUBLISHER_PLAN_PLUS_AI &&
  //       formValues.appOption == APP_OPTION.WITH_APP_PURCHASES_AND_PAID_SUBSCRIPTIONS )
  //     formValues.planType = 9



  //   try{
  //     // register operation
  //     const body = { email: formValues.email, stripeTokenCard: formValues.stripeTokenCard}
  //     await axios.post(`${process.env.REACT_APP_API_URL}/api/users/register`, body)


  //     let n_attempt = 0
  //     const MAX_ATTEMPT = 20
  //     const intervalId = setInterval( async () => {
  //       try{
  //         await axios.get(`${process.env.REACT_APP_API_URL}/api/users/register/success?card=${formValues.stripeTokenCard}`)  
  //         clearInterval( intervalId )

  //         this.setState({ isLoading: false })

  //         // Operation complete relaying to Flickread
  //         await axios.post(`${process.env.REACT_APP_API_URL}/api/users/register/complete`, formValues)  
  //         // // Redirect to apazine
  //         // window.location = "https://publish.apazine.com"
  //         this.setState({ 
  //           showSuccessMessage: true, 
  //           successMessagePlan: planTypeName, 
  //           successMessagePrice: formValues.price
            
  //         })
  //       }catch(err){
  //         console.log("failed")
  //         n_attempt ++;
  //       }

  //       // Order was not confirmed
  //       if(n_attempt > MAX_ATTEMPT){
  //         clearInterval( intervalId )
  //         this.setState({ isLoading: false, modalProcessingFailed: true, currentStep: 1 })
  //       }
  //     }, 500)
      
  //   }catch(e){
  //     //dispatchErrors(e)
  //   }


  // }

  renderStep = () => {
    switch (this.state.currentStep) {
      case 1:
        return <CompanyInfo onSubmit={this.nextStep} />
      case 2:
        return <BillingInfo previousPage={this.prevStep}
          onSubmit={this.nextStep} />
      case 3:
        return <PlanType previousPage={this.prevStep}
          onSubmit={this.nextStep} />
      case 4:
        return <NativeApp previousPage={this.prevStep}
          onSubmit={this.nextStep}  />          
      case 5:
        return <PaymentInfo previousPage={this.prevStep}
          onSubmit={this.onSubmit} onTriggerLoading={this.onTriggerLoading} />
      default:
        return <div>Step1</div>
    }
  }

  render() {

    // Checking whether we are coming back from redirect
    const { payment_intent, redirect_status } = this.getStripeRedirect()
    if( payment_intent && redirect_status === 'succeeded'){
      return <SuccessMessage plan={this.state.successMessagePlan} 
                price={this.state.successMessagePrice} />
    }

    

    
    return (
        <div style={{ display: 'flex', height: '100vh', justifyContent: 'center', background: "#ea5348", overflow: 'auto'}} >
          <div className='login-form'>

            <Grid textAlign='center' style={{ height: '100%' }} verticalAlign='middle'>
              <Grid.Column style={{ maxWidth: 1000 }}>

                <Segment>
                  <Header as='h2' color='black' textAlign='center'>
                    <Image src={Logo} alt='Flickread Logo' style={{ width: "150px" }} />
                  </Header>
                  <Header as='h3' color='black' textAlign='center'>Sign Up</Header>

                  <Step.Group>
                    <Step active={this.state.currentStep === 1} style={{ padding: '1em', paddingLeft: '1.3em'}} >
                      <Icon name='user' />
                      <Step.Content>
                        <Step.Title style={{ color: '#ea5348', fontSize: '1em'}}>Company</Step.Title>
                        <Step.Description style={{ fontSize: '0.8em'}}>Company Information</Step.Description>
                      </Step.Content>
                    </Step>
                    <Step active={this.state.currentStep === 2} style={{ padding: '1em', paddingLeft: '1.3em'}} >
                      <Icon name='address book' />
                      <Step.Content>
                        <Step.Title style={{ color: '#ea5348', fontSize: '1em'}}>Billing</Step.Title>
                        <Step.Description style={{ fontSize: '0.8em'}}>Billing Information</Step.Description>
                      </Step.Content>
                    </Step>
                    <Step active={this.state.currentStep === 3} style={{ padding: '1em', paddingLeft: '1.3em'}} >
                      <Icon name='add to cart' />
                      <Step.Content>
                        <Step.Title style={{ color: '#ea5348', fontSize: '1em'}}>Plan Type</Step.Title>
                        <Step.Description style={{ fontSize: '0.8em'}}>Choose your plan</Step.Description>
                      </Step.Content>
                    </Step>
                    <Step active={this.state.currentStep === 4} style={{ padding: '1em', paddingLeft: '1.3em'}} >
                      <Icon name='mobile alternate' />
                      <Step.Content>
                        <Step.Title style={{ color: '#ea5348', fontSize: '1em'}}>Native App</Step.Title>
                        <Step.Description style={{ fontSize: '0.8em'}}>Optional</Step.Description>
                      </Step.Content>
                    </Step>
                    <Step active={this.state.currentStep === 5} style={{ padding: '1em', paddingLeft: '1.3em'}} >
                      <Icon name='payment' />
                      <Step.Content>
                        <Step.Title style={{ color: '#ea5348', fontSize: '1em'}}>Confirm and Pay</Step.Title>
                        <Step.Description style={{ fontSize: '0.8em'}}>Confirm your account</Step.Description>
                      </Step.Content>
                    </Step>
                  </Step.Group>
                  
                  <Segment attached>
                    {this.renderStep()}
                  </Segment>
                </Segment>
              </Grid.Column>
            </Grid>
       
          </div>

        </div>
  
    )
  }
}

export default Signup;