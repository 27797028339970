import axios from 'axios';

export const getHeader = () => {
  const access_token = localStorage.getItem('access_token')
  if( access_token )
    return { Authorization: `Bearer ${access_token}`}
  return {}
}

export const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 50000,
})

axiosInstance.interceptors.request.use(function (config) {
  config.headers = getHeader();
  return config;
}, function(err) {
  return Promise.reject(err)
})