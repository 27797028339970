import React from 'react';
import {BrowserRouter as Router, Route, } from "react-router-dom";
import { Provider } from "react-redux";
import store from './store'
import { Signup } from './pages/signup'
import { AdminLogin } from './pages/AdminLogin'
import { AdminDashboard } from './pages/admin'
import './App.css';



const App  = () => {
  return <Provider store={store}>
            <Router>
            <Route path="/admin/dashboard/" exact>
                <AdminDashboard />
              </Route>
              <Route path="/admin/" exact>
                <AdminLogin />
              </Route>
              <Route path="/" exact>
                <Signup />                
              </Route>
            </Router>
          </Provider>
  
}

export default App;
