// Redux Store Setup

import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';


import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";

const rootReducer =  combineReducers({
  form: formReducer,
});



// Empty initial state
const initialState = {};

let composeEnhancers = compose;

const enhancer = composeEnhancers(applyMiddleware(thunk));

// Redux store
const store = createStore(rootReducer, initialState, enhancer);

export default store;
