// Choose the Plan Type and the Price for the Subscription

import React from 'react';
import { reduxForm, Field, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import { Button, Header, Grid } from 'semantic-ui-react';
import RenderField from '../common/RenderFieldRedux';
import { PLAN_TYPE } from './common';
import axios from 'axios';

class PlanType extends React.Component {
  state = {
    value1: 1,
    value: 0,
    price: null,
    freeSub: false,
    showNext: false,
    showSlider: true,
    freeCode: 'FREE20',
    ninetynine: 'SAVE99',
    promo: '',
    plan: '',
    productList: []
  }

  calculatePrice = (plan) => {
    // Calculate price
    const product = this.state.productList.find( item => item.key === plan )
    const price = product?.price

    // Set current state
    this.setState({ price, plan, showNext: true})
    this.props.change('planType', plan);
    this.props.change('price', price);
  }

  async componentDidMount() {
    
    // // Get product list
    // const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/product?category=PLAN_TYPE`)
    // this.setState({ productList: response.data })

    // Get product list
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v2/product?category=PLAN_TYPE`)
    this.setState({ productList: response.data })
  }

  render() {
    const { handleSubmit, previousPage } = this.props;
    return (
      <form onSubmit={handleSubmit} size='large'>
        <Header>
          Please select your Flickread plan type
        </Header>
        <Field
          component={RenderField}
          name="planType"
          value={this.state.plan}
          type="hidden"
        />
        <Field
          component={RenderField}
          name="price"
          value={this.state.price}
          type="hidden"
        />
        <Grid stackable>
          {this.state.productList.length>0?
          <Grid.Row centered>            
            <Grid.Column width={5} >
              <div className={`plan-container ${this.state.plan === PLAN_TYPE.PRO_PUBLISHER_PLAN?'active':''}`}
                onClick={() => this.calculatePrice(PLAN_TYPE.PRO_PUBLISHER_PLAN)}>
                <h3 className='text-center'>
                  <div>{this.state.productList[0].name}</div>
                  <small>£{this.state.productList[0].price} per month plus VAT</small>
                </h3>
                <div className='feature'>Flickread Online Digital Publishing</div>
                <div className='feature'>Customisable Storefront</div>
                <div className='feature'>Desktop & Mobile </div>
                <div className='feature last'>PDF Replica</div>
              </div>
            </Grid.Column>
            <Grid.Column width={5}>
              <div className={`plan-container ${this.state.plan === PLAN_TYPE.PRO_PUBLISHER_PLAN_PLUS?'active':''}`}
                onClick={() => this.calculatePrice(PLAN_TYPE.PRO_PUBLISHER_PLAN_PLUS)}>
                <h3 className='text-center'>
                  <div>{this.state.productList[1].name}</div>
                  <small>£{this.state.productList[1].price} per month plus VAT</small>
                </h3>
                <div className='feature'>Flickread Online Digital Publishing </div>
                <div className='feature'>Customisable Storefront</div>
                <div className='feature'>Desktop & Mobile </div>
                <div className='feature'>PDF Replica</div>
                <div className='feature'>FreeFlow A.I. PDF to phone friendly Free Flowing Text conversion</div>
                <div className='feature'>Automatic Translation into any language</div>
                <div className='feature last'>Text to read-out-loud (using device in-built speech)</div>
              </div>
            </Grid.Column>
            <Grid.Column width={5}>
              <div className={`plan-container ${this.state.plan === PLAN_TYPE.PRO_PUBLISHER_PLAN_PLUS_AI?'active':''}`}
                onClick={() => this.calculatePrice(PLAN_TYPE.PRO_PUBLISHER_PLAN_PLUS_AI)}>
                <div>
                  <h3 className='text-center'>
                    <div>{this.state.productList[2].name}</div>
                    <small>£{this.state.productList[2].price} per month plus VAT</small>
                  </h3>
                  <div className='feature'>Flickread Online Digital Publishing</div>
                  <div className='feature'>Customisable Storefront</div>
                  <div className='feature'>Desktop & Mobile</div>
                  <div className='feature'>PDF Replica</div>
                  <div className='feature'>FreeFlow A.I. PDF to phone friendly Free Flowing Text conversion</div>
                  <div className='feature'>Automatic Translation into any language</div>
                  <div className='feature last mb-2'>Text read out loud using AI natural human text to speech conversion</div>
                </div>                
              </div>
            </Grid.Column>            
          </Grid.Row>
          :null}

          <Grid.Row>
            <Grid.Column width={16}>
              <Button color='blue' style={{ background: '#ea5348'}}
                onClick={previousPage}>
                Previous
              </Button>
              <Button 
                  color='blue'
                  disabled={this.state.showNext==false}
                  style={{ background: '#ea5348'}}>
                Next
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>


      </form>
    )
  }
}

const validate = (formValues) => {
  const errors = {};
  return errors;
}

const formWrapped = reduxForm({
  form: 'wizard', //                 <------ same form name
  destroyOnUnmount: false, //        <------ preserve form data
  forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
  validate
})(PlanType);

const selector = formValueSelector('wizard')
export default connect(state => {
  //const { country } = selector(state, 'country')
  return { country: 'GB' }
})(formWrapped);

