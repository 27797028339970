import React, { useEffect, useState } from 'react';
import Logo from './../../assets/imgpsh_fullsize_anim.png';
import { useHistory } from "react-router-dom";

import { ToastContainer, toast, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { Button, Header, Image, Input, Icon } from 'semantic-ui-react';
import { axiosInstance } from "../../utils";
import "./admin.css"

const ProductPrices = () => {
  const [ productList, setProductList ] = useState([])
  const [ appList, setAppList ] = useState([])
  const onSave = async () => {
    try{
      const body = [ ...productList, ...appList ]
      await axiosInstance.post(`/api/v2/product/`, body)
      toast("Products Prices Saved")
    }catch(e){
      toast("Failed to store products")
    }
  }

  useEffect( () => {
    ( async () => {
      try{
        axiosInstance.get(`/api/v2/product/?category=PLAN_TYPE`).then( response => {
          setProductList([...response.data])
        })
        axiosInstance.get(`/api/v2/product/?category=APP_OPTION`).then( response => {
          setAppList([...response.data])
        })
        
      }catch(e){
        toast("Failed to retrieve products")
      }
    })();
  }, [])

  const onChangeProduct = ( e, target, key ) => {
    const productListLocal = [ ...productList ]
    const productListLocalItem = productListLocal.find( item => item.id === target.id)
    if( key === 'price') productListLocalItem[key] = e.target.valueAsNumber
    if( key === 'name') productListLocalItem[key] = e.target.value
    setProductList( productListLocal )
  }

  const onChangeApp = ( e, target, key ) => {
    const appListLocal = [ ...appList ]
    const appListLocalItem = appListLocal.find( item => item.id === target.id)
    if( key === 'price') appListLocalItem[key] = e.target.valueAsNumber
    if( key === 'name') appListLocalItem[key] = e.target.value
    setAppList( appListLocal )
  }

  return <div style={{ padding: '2em'}}>
            <h2>Product Prices</h2>
            <hr className='separator'/>

            <div style={{ width: '80%', maxWidth: "700px"}}>
              <h2 style={{ margin: '1em 0.5em'}}>Plans</h2>
              {productList.map( (item, idx) => 
              <div className="d-flex" key={idx} style={{ marginTop: '2em', display: 'flex', width: '100%' }}>
                <div style={{ width: '50%', padding: '0 1em'}}>
                  <Input fluid size='large' label={"Name"}
                    value={item.name} onChange={(e) => onChangeProduct(e, item, 'name')}
                    labelPosition='right' type="text"
                    style={{ flexGrow: 1, padding: 0 }} />
                </div>
                <div style={{ width: '50%'}}>
                  <Input fluid size='large' label={"Price"} 
                    value={item.price} onChange={(e) => onChangeProduct(e, item, 'price')}
                    labelPosition='right' type="number"
                    style={{ flexGrow: 1, padding: 0 }} />
                </div>
              </div>
              )}

              <h2 style={{ margin: '1em 0.5em'}}>Apps</h2>
              {appList.map( (item, idx) => 
              <div className="d-flex" key={idx} style={{ marginTop: '2em', display: 'flex', width: '100%' }}>
                <div style={{ width: '50%', padding: '0 1em'}}>
                  <Input fluid size='large' label={"Name"}
                    value={item.name} onChange={(e) => onChangeApp(e, item, 'name')}
                    labelPosition='right' type="text"
                    style={{ flexGrow: 1, padding: 0 }} />
                </div>
                <div style={{ width: '50%'}}>
                  <Input fluid size='large' label={"Price"} 
                    value={item.price} onChange={(e) => onChangeApp(e, item, 'price')}
                    labelPosition='right' type="number"
                    style={{ flexGrow: 1, padding: 0 }} />
                </div>
              </div>
              )}
              <div className="d-flex justify-content-end" style={{ padding: "20px 0" }}>
                <Button color='blue' style={{ background: '#ea5348'}}
                  onClick={() => onSave() }>
                  Save
                </Button>
              </div>
            </div>

          </div>
}

const PromoCodes = () => {
  const [ promoCodeList, setPromoCodeList ] = useState([])

  const refresh = async () => {
    try{
      const response = await axiosInstance.get(`/api/v2/promocode/`)
      const promoCodeList = [...response.data]
      promoCodeList.push({ name: '', percentage: '' })
      setPromoCodeList(promoCodeList)
    }catch(e){
      toast("Failed to retrieve promocodes")
    }
  }

  useEffect( () => {   
    refresh()
  }, [])

  const onSave = async () => {
    try{
      const promoCodeListLocal = promoCodeList.filter( item => item.name.length > 0 )
      const body = [ ...promoCodeListLocal ]
      await axiosInstance.post(`/api/v2/promocode/`, body)
      toast("Promo Codes Saved")
      refresh()
    }catch(e){
      toast("Failed to store Promo Code")
    }
  }

  const onAdd = () => {
    const promoCodeListLocal = JSON.parse(JSON.stringify(promoCodeList))
    promoCodeListLocal.push({ name: '', percentage: '' })
    setPromoCodeList(promoCodeListLocal)
  }

  const onChange = (idx, key, value) => {
    // Validate percentage
    if( key === 'percentage' && value > 100 ) return
    if( key === 'percentage' && value < 0 ) return
    // Set value
    const promoCodeListLocal = JSON.parse(JSON.stringify(promoCodeList))
    promoCodeListLocal[idx][(key)] = value
    setPromoCodeList(promoCodeListLocal)
  }

  const onDelete = (idx) => {
    const promoCodeListLocal = JSON.parse(JSON.stringify(promoCodeList))
    promoCodeListLocal.splice(idx, 1)
    setPromoCodeList(promoCodeListLocal)
  }

  return <div style={{ padding: '2em'}}>
            <h2>Promo Codes</h2>
            <hr className='separator'/>

            <div style={{ width: '80%', maxWidth: "700px"}}>
              {promoCodeList.map( (item, idx) => 
                <div key={idx} style={{ display: 'flex', marginTop: '2em'}}>
                  <Input fluid size='large' label={"Name"} value={item.name}
                    onChange={(e) => onChange(idx, 'name', e.target.value)} labelPosition='right' type={"text"}
                    style={{ flexGrow: 1, padding: 0, marginRight: '3em' }} />
                  <Input fluid size='large' label={"Percentage"}  value={item.percentage}
                    onChange={(e) => onChange(idx, 'percentage', e.target.value)} labelPosition='right' type={"number"} 
                    style={{ width: "200px", padding: 0 }}  />
                  <div className='d-flex justify-content-center align-items-center' style={{ marginLeft: '1em'}}>
                    <Icon name="delete" size="big" className='cursor-pointer' onClick={() => onDelete(idx)} />
                  </div>
                </div>
              )}


              <div className="d-flex justify-content-between" style={{ padding: "20px 0" }}>
                <Button color='blue' style={{ background: '#ea5348'}}
                  onClick={() => onAdd() }>
                  Add 
                </Button>
                <Button color='blue' style={{ background: '#ea5348'}}
                  onClick={() => onSave() }>
                  Save
                </Button>
              </div>
            </div>

          </div>
}

const MENU_ITEM = { PRODUCT_PRICES: 'PRODUCT_PRICES', PROMO_CODES: 'PROMO_CODES'}
export const AdminDashboard = () => {
  const history = useHistory();
  const [ menuItem, setMenuItem ] = useState(MENU_ITEM.PRODUCT_PRICES)

  const whoami = async () => {
    console.log("whoami")
    //const response = await axios()

    try{
      const response = 
        await axiosInstance.get(`/api/whoami/`)
      // // Store access_token
      // window.localStorage.setItem('access_token', response.data.access_token)
      // // Move to dashboard
      // history.push("/admin/")
    }catch(e){
      toast("Session Expired")
      setTimeout( () => history.push("/admin/"), 2000)
    }
  }
  

  useEffect( () => {
    whoami()
  }, [])

  return  <main style={{ display: 'flex', height: '100vh', background: '#ea5348', overflow: 'auto'}}>
            <section style={{ width: '20%', background: '#ea5348', padding: '0.5em 0 0.5em 0.5em', display: 'flex'}}>
              <div style={{ background: 'white', borderRadius: '5px', flexGrow: 1, padding: '0 1em', 
                display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
                <div>
                  <Header as='h2' color='black' textAlign='center' style={{ padding: '0.5em 1em'}}>
                    <Image src={Logo} alt='Flickread Logo' style={{ width: "150px" }} />
                  </Header>                
                  <hr className='separator'/>
                  <div style={{ marginTop: '1.3em'}}>
                    <div className={`promo-codes ${menuItem === MENU_ITEM.PRODUCT_PRICES?'active':''}`}
                      onClick={() => setMenuItem(MENU_ITEM.PRODUCT_PRICES)} >
                      Product Prices
                    </div>
                  </div>
                  <div style={{ marginTop: '1.3em'}}>
                    <div className={`promo-codes ${menuItem === MENU_ITEM.PROMO_CODES?'active':''}`}
                      onClick={() => setMenuItem(MENU_ITEM.PROMO_CODES)}>
                      Promo Codes
                    </div>
                  </div>
                </div>
                <div style={{ padding: "20px 10px", textAlign: 'center' }}>
                  <Button color='blue' style={{ background: '#ea5348'}}
                    onClick={() => console.log("logout") }>
                    Logout
                  </Button>
                </div>
                
              </div>
                
            </section>
            <section style={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
              <section style={{ background: '#ea5348 ', height: '75px', padding: '0.5em 0.5em 0 0.5em', display: 'flex' }}>
                <div style={{ background: 'white', borderRadius: '5px', flexGrow: 1, 
                  display: 'flex', justifyContent: 'end', alignItems: 'center', padding: '1em'}}>
                    <Icon size={"big"} name="user" />
                    <b style={{ marginLeft: '0.5em'}}>user@mail.com</b>                    
                </div>
              </section>
              <section style={{ padding: '0.5em', flexGrow: 1}}>
                <div style={{ background: 'white', minHeight: '100%', height: '0px', borderRadius: '5px', overflow: 'auto'}}>
                  {menuItem === MENU_ITEM.PRODUCT_PRICES?<ProductPrices />:null}
                  {menuItem === MENU_ITEM.PROMO_CODES?<PromoCodes />:null}
                  
                </div>
              </section>
            </section>
            <ToastContainer 
              position="bottom-right"
              transition={Slide} 
              autoClose={1000}
              closeButton={false}
              rtl 
              hideProgressBar={true}
              className={'flickread-toast-message'}/>
          </main>
}
