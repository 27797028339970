import React, { useState } from 'react';
import Logo from './../assets/imgpsh_fullsize_anim.png';
import { Button, Header, Image, Grid, Input } from 'semantic-ui-react';
import { useHistory } from "react-router-dom";
import { ToastContainer, toast, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { axiosInstance } from '../utils';


export const AdminLogin = () => {
  const history = useHistory();
  const [ credentials, setCredentials ] = useState({ username: '', password: ''})

  const onLogin = async () => {
    try{
      const response = 
        await axiosInstance.post(`/api/login/`, {...credentials})
      // Store access_token
      window.localStorage.setItem('access_token', response.data.access_token)
      // Move to dashboard
      history.push("/admin/dashboard")
    }catch(e){
      toast("Wrong credentials")
    }
  }

  const isSubmit = (e) => {
    if(e.keyCode === 13) onLogin()
  }

  return  <div style={{ display: 'flex', height: '100vh', justifyContent: 'center', alignItems: 'center', background: "#ea5348"}} >
            <Grid stackable style={{ width: '100%', padding: '1em'}}>
              <Grid.Row centered>
                <Grid.Column width={4} style={{ background: 'white', borderRadius: '5px' }}>
                  <Header as='h2' color='black' textAlign='center' style={{ padding: '0.5em 1em'}}>
                    <Image src={Logo} alt='Flickread Logo' style={{ width: "150px" }} />
                  </Header>
                  <div style={{ padding: "5px 10px" }}>
                    <Input fluid size='large' label={"Username"} value={credentials.username}
                      onKeyDown={(e) => isSubmit(e)}
                      onChange={(e) => setCredentials({ ...credentials, username: e.target.value })}
                      labelPosition='right' type="text" style={{ padding: 0 }}/>      
                  </div>
                  <div style={{ padding: "5px 10px" }}>
                    <Input fluid size='large' label={"Password"}  value={credentials.password}
                      onKeyDown={(e) => isSubmit(e)}
                      onChange={(e) => setCredentials({ ...credentials, password: e.target.value })}
                      labelPosition='right' type="password" style={{ padding: 0 }}/>      
                  </div>
                  <div style={{ padding: "20px 10px", textAlign: 'center' }}>
                    <Button color='blue' style={{ background: '#ea5348'}}
                      onClick={() => onLogin()}>
                      Login
                    </Button>
                  </div>

                </Grid.Column>
              </Grid.Row>
            </Grid>
            <ToastContainer 
              position="bottom-right"
              transition={Slide} 
              autoClose={1000}
              closeButton={false}
              rtl 
              hideProgressBar={true}
              className={'flickread-toast-login'}/>
          </div> 
}